<template>

	<div class="user">
		<div class="card">
            <h3>{{obj.card_id | ctype}}</h3>
            <p class="p1">{{obj.end_time}} 到期</p>
            <p class="p2">说明：在有效期内可允许使用该体验卡报名的活动</p>
            <div class="left">{{obj.card_id | have}}</div>
            <div class="right" @click="tourl2">体验卡购买</div>
        </div>

        <h4>购买记录</h4>
		
		 <div class="couitem" v-if="sign==1">
            <table border="1" cellpadding="0" cellspacing="0" >
                <tr>
                    <th>购买日期</th>
                    <th>体验卡类型</th>
                    <th>有效期</th>
                    <th>价格（元）</th>
                </tr>
                <tr v-for="(item,i) in list" :key="i">
                    <td>{{item.start_time}}</td>
                    <td>{{item.card_id | ctype}}</td>
                    <td>{{item.end_time}}</td>
                    <td>{{item.order_price}}</td>
                </tr>
                
            </table>
        </div>
	</div>

</template>

<script>
import { orderMemberListAPI} from "@/api/user";
export default {

  data () {
    return {
      sign: 1,
      list:[],
      obj:{}

    }
  },
  created () {
    this.getList()
  },
  filters:{
    ctype(t){
      switch(t){
        case 1:
          return "月卡"
        break;
         case 2:
          return "季卡"
        break;
         case 3:
          return "年卡"
        break;
        default:
          return "未拥有"
      }
    },
    have(h){
      if (h==1 || h==2 || h==3) {
        return "当前拥有体验卡"
      }
      return "当前未购买体验卡"
    }
  },
  methods: {
   
    tourl2 () {
      this.$router.push({ path: '/user/buycard' })
    },
    getList(){
		  orderMemberListAPI().then(res=>{
			  this.list=res.cateList
        // this.obj=res.order_member
		  })
	  }
  }
}
</script>

<style lang="scss" scoped="scoped">

	.user{
		padding: 5px 20px 20px;
		background: #ffffff;
        min-height: 600px;
		.card{
            position: relative;
            height: 168px;
            margin-top: 10px;
            background: linear-gradient(90deg,#ffbc57 7%, #ffa500);
            border-radius: 8px;
            text-align: center;
            padding-top: 20px;
            h3{
                font-size: 30px;
                color: #6d380c;
                line-height:52px;  
               
            }
            .p1{
                font-size: 18px;
                font-weight: 500;
                color: #333333;
                line-height: 45px;
            }
            .p2{
                font-size: 14px;
                color: #333333;
                line-height: 25px;
            }
            .left{
                position: absolute;
                top: 20px;
                left: 20px;
                 font-size: 14px;
            }
            .right{
                position: absolute;
                top: 20px;
                right: 20px;
                width: 91px;
                height: 35px;
                line-height: 35px;
                background: #ffffff;
                border-radius: 4px;
                color: #ff4c12;
                font-size: 14px;
                font-weight: 500;
                cursor: pointer;
            }

        }

        h4{
            color: #333333;
           margin-top: 20px;
        }
		
		table{
			width: 100%;
			border-color:#fcfcfc; 
			margin-top: 20px;
			th{
				background: #eaeaea;
				line-height: 30px;
				width: 25%;
				font-size: 13px;
        color: #666666;
			}
			td{
				text-align: center;
				line-height: 40px;
				font-size: 13px;
			}
			td:nth-child(4){
				color: #FF7C00	
			}
		}
	
	}

</style>
